import {
  Box,
  Button,
  chakra,
  Container,
  Flex,
  Grid,
  GridItem,
  Text,
  shouldForwardProp,
  Heading,
} from '@chakra-ui/react';
import {
  motion,
  useMotionValue,
  useScroll,
  AnimatePresence,
  useDragControls,
  isValidMotionProp,
} from 'framer-motion';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { SliderItem } from './SliderItem';

import FadeInAnimation from '../UX/FadeInAnimation';
import RouterLink from '../Utility/Routing/RouterLink';
import GImage from '../Utility/GImage';

function FoerdermittelSlider({ backG }) {
  const x = useMotionValue(0);
  useEffect(() => {
    x.onChange(() => {
      console.log('Damn it');
      console.log(x.get());
    });
  }, []);

  return (
    <Box bg={backG}>
      <Container pt={24} px={0} variant={''}>
        <>
          <SliderItem as={motion.div} btnVariant={'solid'}>
            <Grid display={'grid'} templateRows='1fr' templateColumns='1fr'>
              <GridItem colSpan={1} rowSpan={1} colStart={1} rowStart={1}>
                <GImage
                  minH={{ base: '40vh', md: '50vh' }}
                  opacity={0.4}
                  src={'mood/amy-hirschi-W7aXY5F2pBo-unsplash.jpg'}
                  alt={' '}
                  w={'100%'}
                  h={'100%'}
                  objectFit={'cover'}
                  objectPosition={'center'}
                />
              </GridItem>
              <GridItem
                zIndex={999999999999}
                p={{ base: '8', md: '12', lg: '24' }}
                colSpan={1}
                rowSpan={1}
                colStart={1}
                rowStart={1}
              >
                <FadeInAnimation
                  delay={0.5}
                  duration={0.9}
                  threshold={0}
                  initialX={0}
                  initialY={12}
                  mb={8}
                >
                  <Heading
                    as='h2'
                    size={{ base: 'sm', sm: 'md', md: 'lg', lg: 'xl' }}
                    w={{ base: '100%', lg: '76%' }}
                    color={'brand.green'}
                  >
                    Fördermittelmanagement
                  </Heading>
                </FadeInAnimation>
                <FadeInAnimation
                  delay={1}
                  duration={0.9}
                  threshold={0}
                  initialX={0}
                  initialY={12}
                >
                  <Heading
                    size={{ base: 'xs', sm: 'sm', md: 'sm', lg: 'md' }}
                    lineHeight={'base'}
                    color={'brand.gray.900'}
                  >
                    Die gesamte Dienstleistung „Fördermittel“ ist für Sie als
                    Unternehmen erfolgsabhängig zu betrachten, da wir
                    ausschließlich von Ihren jeweiligen durch uns erarbeiteten
                    Förderzusagen partizipieren.
                  </Heading>
                </FadeInAnimation>
              </GridItem>
            </Grid>
          </SliderItem>
          <SliderItem as={motion.div} btnVariant={'solid'}>
            <Box p={{ base: '8', md: '12', lg: '24' }}>
              <FadeInAnimation
                delay={0.5}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={12}
              >
                <Heading
                  size={{ base: 'xs', sm: 'sm', md: 'sm', lg: 'md' }}
                  w={{ base: '100%', md: '76%' }}
                  color={'brand.green'}
                  lineHeight={'base'}
                >
                  Optimieren Sie das Fördermittel-Management für Ihr HR und
                  lassen Sie die Ihnen zur Verfügung stehenden Gelder nicht
                  ungenutzt!
                </Heading>
              </FadeInAnimation>
              <FadeInAnimation
                delay={1}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={0}
              >
                <Heading
                  mt={4}
                  size={{ base: 'xs', sm: 'sm', md: 'sm', lg: 'md' }}
                  w={{ base: '100%', md: '76%' }}
                  lineHeight={'base'}
                  color={'white'}
                >
                  Unsere über 20jährige Erfahrung im Dschungel des
                  Fördermittelmanagements spricht für sich. Wir kooperieren und
                  arbeiten hier bereits mit vielen Großunternehmen und Konzernen
                  in Deutschland erfolgreich zusammen. Gerne lassen wir auch Ihr
                  Unternehmen an unserem Erfolg teilhaben.
                </Heading>
              </FadeInAnimation>

              <FadeInAnimation
                delay={1.5}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={12}
              >
                <Heading
                  as='h3'
                  size={{ base: 'xs', sm: 'sm', md: 'sm', lg: 'md' }}
                  w={{ base: '100%', md: '76%' }}
                  color={'white'}
                  lineHeight={'base'}
                  mt={4}
                >
                  Für den gesamten Bereich HR Ihres Unternehmens recherchieren,
                  beraten und beantragen wir für Sie individuell die
                  entsprechenden Fördermittel.
                </Heading>
              </FadeInAnimation>
              <FadeInAnimation
                delay={2}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={0}
              >
                <Heading
                  mt={4}
                  as='h3'
                  size={{ base: 'xs', sm: 'sm', md: 'sm', lg: 'md' }}
                  w={{ base: '100%', md: '76%' }}
                  color={'white'}
                >
                  Ebenso die gesamte Abwicklung mit etwaigen Änderungen über den
                  gesamten Förderzeitraum wird von uns übernommen.
                </Heading>
              </FadeInAnimation>
            </Box>
          </SliderItem>
          <SliderItem
            display='flex'
            w={'100%'}
            px={'10%'}
            as={motion.div}
            btnVariant={'solid'}
          >
            <Flex
              flexDirection={{ base: 'column', md: 'column', lg: 'row' }}
              w={'100%'}
              h={'100%'}
            >
              <FadeInAnimation
                delay={0.5}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={0}
                w={'100%'}
                h={'100%'}
              >
                <GImage
                  minH={{ base: '30vh', md: '50vh' }}
                  src={'team/kdw-team-6687.jpg'}
                  alt={' '}
                  w={'100%'}
                  h={'100%'}
                  objectFit={'cover'}
                  objectPosition={'left'}
                />
              </FadeInAnimation>
              <Flex
                p={{ base: 12, md: 8, lg: 8 }}
                px={12}
                w={'100%'}
                h={'100%'}
                minW={'40%'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'start'}
              >
                <Heading
                  mt={4}
                  mb={4}
                  as='h3'
                  size={{ base: 'xs', sm: 'md', md: 'lg', lg: 'xl' }}
                  color={'white'}
                >
                  Wir stehen jederzeit für Sie bereit!
                </Heading>
                <RouterLink
                  mx={'auto'}
                  display={'inline-block'}
                  isExternal={'false'}
                  link={'/kontakt'}
                >
                  <Button
                    mt={2}
                    px={14}
                    pt={4}
                    pb={9}
                    fontSize='md'
                    textAlign='center'
                    variant={'solid'}
                    color='white'
                    bg='brand.green'
                  >
                    Kontakt
                  </Button>
                </RouterLink>
              </Flex>
            </Flex>
          </SliderItem>
        </>
      </Container>
    </Box>
  );
}

export default FoerdermittelSlider;
